import React from "react"
import { Helmet } from "react-helmet"
import classnames from "classnames"
import { Link } from "gatsby"

import Logo from "../../static/images/Logo_top.svg"

const Header = () => {
  const [nearTop, setNearTop] = React.useState(true)

  React.useEffect(() => {
    const handleScroll = e => {
      if (e.target.scrollingElement.scrollTop < 21) {
        setNearTop(true)
      } else if (e.target.scrollingElement.scrollTop > 20 && nearTop) {
        setNearTop(false)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return function cleanupListener() {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  return (
    <nav className="navbar navbar-multiline navbar-expand-lg">
      <Helmet
        htmlAttributes={{
          class: "mobile",
          lang: "en",
        }}
        bodyAttributes={{
          class: classnames("loaded", {
            top: nearTop,
          }),
        }}
      >
        <title>Scandinavian Card Issuing Services AB</title>
        <meta
          name="description"
          content="A blazing fast Gatsby website for SCIS"
        />
        <meta charSet="utf-8" />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/favicon/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/favicon/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/favicon/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/favicon/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/favicon/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/favicon/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/favicon/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/favicon/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/favicon/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicon/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-TileImage"
          content="/favicon/ms-icon-144x144.png"
        />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <div className="navbar-line" style={{ height: 0 }}>
        {/*  height: 0px when affix-skip is present below, else set to size of div (approx 39px)*/}
        <div className="navbar-wrapper affix navbar-light affix-skip affix-fixed bg-white shadow-dark-sm">
          <div className="container px-sm-0">
            <Link
              className="navbar-brand fw-regular pl-0"
              to="/"
              style={{ display: "inline-flex" }}
            >
              <Logo className="mt-2 mb-2" />
              {/* <svg className="mt-2 mb-2" src="/static/images/Logo_top.svg"></svg> */}
              {/* <img src={"images/Logo_top.svg"} alt="" title="Logo" /> */}
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Header
