import React from "react"
import LogoBottom from "../../static/images/Logo_bottom.svg"

const Footer = () => {
  return (
    <footer className="footer footer-light text-center d-md-flex flex-row justify-content-between mx-lg-12 mb-lg-10 mx-md-10 mb-md-8">
      <LogoBottom
        style={{
          height: 45,
        }}
      />
      <div className="fs-sm text-muted align-self-center pt-2 py-md-0">
        Scandinavian Card Issuing Services AB &copy; 2020
      </div>
    </footer>
  )
}

export default Footer
